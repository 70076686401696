
import React from 'react';
export default class CategList extends React.Component {
    constructor() {
        super();
        this.state = {
            data: [
                {
                    id: "1",
                    title: "ACER",
                    description: "„We delivered over 100,000 products and  each delivery means a fulfilled promise.” ",
                    categories: [
                        { categ: 'Headphones' },
                        { categ: 'Laptops' },
                        { categ: 'Notebook Bags' },
                        { categ: 'Monitors' },
                        { categ: 'Mice/Keyboards' },
                        { categ: 'Projectors' }
                    ]
                },
                {
                    id: "2",
                    title: "APPLE",
                    description: "„With over 200 types to choose from, our selection includes a variety of products to meet the needs of both commercial and consumer clients.”",
                    categories: [
                        { categ: 'Headphones' },
                        { categ: 'Laptops' },
                        { categ: 'Monitors' },
                        { categ: 'Mice/Keyboards' },
                        { categ: 'Smart Phones' },
                        { categ: 'TV Streaming Devices' }
                    ]
                },
                {
                    id: "3",
                    title: "ASUS",
                    description: "„We understand that as the business world changes, so do our customers' needs. That is why we are continuously expanding our range of products and categories, ensuring that we can meet diverse requirements.”",
                    categories: [
                        { categ: 'Gaming Consoles & Accessories' },
                        { categ: 'Headphones' },
                        { categ: 'Laptops' },
                        { categ: 'Notebook Bags' },
                        { categ: 'Motherboards' },
                        { categ: 'Monitors' },
                        { categ: 'Mice/Keyboards' },
                        { categ: 'Wireless Routers & Repeaters' },
                        { categ: 'Smart Phones' },
                        { categ: 'Video Cards' }
                    ]
                },
                {
                    id: "4",
                    title: "DELL",
                    description: "We are constantly looking for new solutions, products and ideas to address existing challenges and create new opportunities",
                    categories: [
                        { categ: 'Laptops' },
                        { categ: 'Monitors' }
                    ]
                },
                {
                    id: "5",
                    title: "HONOR",
                    description: "We are constantly looking for new solutions, products and ideas to address existing challenges and create new opportunities",
                    categories: [
                        { categ: 'Bluetooth Speakers' },
                        { categ: 'Headphones' },
                        { categ: 'Smart Phones' }
                    ]
                },
                {
                    id: "6",
                    title: "HP",
                    description: "We are constantly looking for new solutions, products and ideas to address existing challenges and create new opportunities",
                    categories: [
                        { categ: 'Headphones' },
                        { categ: 'Laptops' },
                        { categ: 'Printers' },
                        { categ: 'Notebook Bags' },
                        { categ: 'Monitors' },
                        { categ: 'Mice/Keyboards' },
                        { categ: 'Toner Office Supplies' },
                        { categ: 'Servers & Server Options' }
                    ]
                },
                {
                    id: "7",
                    title: "LENOVO",
                    description: "We are constantly looking for new solutions, products and ideas to address existing challenges and create new opportunities",
                    categories: [
                        { categ: 'Headphones' },
                        { categ: 'Laptops' },
                        { categ: 'Notebook Bags' },
                        { categ: 'Monitors' },
                        { categ: 'Mice/Keyboards' }
                    ]
                },
                {
                    id: "8",
                    title: "MSI",
                    description: "We are constantly looking for new solutions, products and ideas to address existing challenges and create new opportunities",
                    categories: [
                        { categ: 'Laptops' },
                        { categ: 'Notebook Bags' },
                        { categ: 'Motherboards' },
                        { categ: 'Mice/Keyboards' },
                        { categ: 'Video Cards' }
                    ]
                },
                {
                    id: "9",
                    title: "NINJA",
                    description: "We are constantly looking for new solutions, products and ideas to address existing challenges and create new opportunities",
                    categories: [
                        { categ: 'Smart Home Appliances' }
                    ]
                },
                {
                    id: "10",
                    title: "ROBOROCK",
                    description: "We are constantly looking for new solutions, products and ideas to address existing challenges and create new opportunities",
                    categories: [
                        { categ: 'Smart Home Appliances' }
                    ]
                },
                {
                    id: "11",
                    title: "SAMSUNG",
                    description: "We are constantly looking for new solutions, products and ideas to address existing challenges and create new opportunities",
                    categories: [
                        { categ: 'Headphones' },
                        { categ: 'Laptops' },
                        { categ: 'Monitors' },
                        { categ: 'Smart Phones' }
                    ]
                }
                // sa pun si xiaomi
            ],
        }
    }
    getData() {
        return this.state.data;
      }
}