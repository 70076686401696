import ACER from "./mediabrands/ACER.png"
import APPLE from "./mediabrands/APPLE.png"
import ASUS from "./mediabrands/ASUS.png"
import DELL from "./mediabrands/DELL.png"
import HONOR from "./mediabrands/HONOR.png"
import HP from "./mediabrands/HP.png"
import LENOVO from "./mediabrands/LENOVO.png"
import MSI from "./mediabrands/MSI.png"
import NINJA from "./mediabrands/NINJA.png"
import ROBOROCK from "./mediabrands/ROBOROCK.png"
import SAMSUNG from "./mediabrands/SAMSUNG.png"
import XIAOMI from "./mediabrands/XIAOMI.png"
export const logoImg =[
    {
        logoName:"ACER",
        img: ACER,
    },
    {
        logoName:"APPLE",
        img: APPLE,
    },
    {
        logoName:"ASUS",
        img: ASUS,
    },
    {
        logoName:"DELL",
        img: DELL,
    },
    {
        logoName:"HONOR",
        img: HONOR,
    },
    {
        logoName:"HP",
        img: HP,
    },
    {
        logoName:"LENOVO",
        img: LENOVO,
    },
    {
        logoName:"MSI",
        img: MSI,
    },
    {
        logoName:"NINJA",
        img: NINJA,
    },
    {
        logoName:"ROBOROCK",
        img: ROBOROCK,
    },
    {
        logoName:"SAMSUNG",
        img: SAMSUNG,
    },
    {
        logoName:"XIAOMI",
        img: XIAOMI,
    }
]