import Commitment from "../media/commitment.png"
import Diversity from "../media/diversity.png"
import Adaptable from "../media/adaptable.png"
import Innovation from "../media/innovation.png"
import Passion from "../media/passion.png"
import Courage from "../media/courage.png"

export const details=[
    {
    id:"1",
    title:"Commitment",
    description:"   „We delivered over 100,000 products and  each delivery means a fulfilled promise.” ",
    img:Commitment,
    },
    {
    id:"2",
    title:"Diversity",
    description:"   „With over 200 types to choose from, our selection includes a variety of products to meet the needs of both commercial and consumer clients.”",
    img:Diversity,
    },
    {
    id:"3",
    title:"Adaptable",
    description:"   „We understand that as the business world changes, so do our customers' needs. That is why we are continuously expanding our range of products and categories, ensuring that we can meet diverse requirements.”",
    img:Adaptable,
    },
    {
    id:"4",
    title:"Innovation",
    description:"   „We are constantly looking for new solutions, products and ideas to address existing challenges and create new opportunities.”",
    img:Innovation,
    },
    {
    id:5,
    title:"Passion",
    description:"   „The years of experience accumulated by our team members serve as a foundation for their passion, providing them with insights, problem-solving skills, and a nuanced understanding of the intricacies within the distribution and retail business.”",
    img:Passion,
    },
    {
     id:6,
    title:"Courage",
    description:"   „At the heart of our values, courage is the audacity to dream big and the resilience to convert those aspirations into reality. We fearlessly face industry challenges and actively seeking out new frontiers, pushing boundaries, and embracing change.”",
    img:Courage 
    }
]

