import Alin from "./mediaAboutUs/alin.png";
import Adi from "./mediaAboutUs/adi.png";
import Mara from "./mediaAboutUs/mara.png";
import Anton from "./mediaAboutUs/anton.png";

export const empDetails=[
    {
    id:"1",
    name:"Nistor Alin",
    email:"alin@e-sales.tech",
    job:"Purchase Manager",
    experience:"https://www.linkedin.com/in/alin-nistor-948437287/",
    img:Alin,
    pictureSide:"left",
    },
    {
    id:"2",
    name:"Adrian Stincaseanu",
    email:"adrian@e-sales.tech",
    job:"Purchase Manager",
    experience:"https://www.linkedin.com/in/adrian-stincaseanu-6564611/",
    img:Adi,
    pictureSide:"right"
    },
    {
    id:"3",
    name:"Mara Burca",
    email:"mara@e-sales.tech",
    job:"Sales Manager",
    experience:"https://www.linkedin.com/in/mara-burca-b60088223/",
    img:Mara,
    pictureSide:"left"
    },
    {
    id:"4",
    name:"Anton Makiejczik",
    email:"anton@e-sales.tech",
    job:"Sales Manager",
    experience:"https://www.linkedin.com/in/anton-makiejczik-58b207209/",
    img:Anton,
    pictureSide:"right"
    },
]














