import React from 'react';
import { EmployeeCard } from './EmployeeCard'
import "./Aboutus.css"
import LogoPicture from '../../layout/LogoPicture.js'

export const Aboutus = () => {
    return (
        <div>
            <div className="firstdiv" >
                <div>
                    <div className='firstdivcomp'>
                        <img className='firstdivImg' src={LogoPicture.tel} alt='' />
                        <h1 className='techedges'> TECH EDGE</h1>
                        <h4 className='ccs'>computers & components</h4>
                        <h6 className='ldpcs'>Laptops •  Desktops • Printing • Components • Small Domestic Appliance</h6>
                        <h6 className='ldpcs0'>Laptops •  Desktops • Printing • Components</h6>
                        <h6 className='ldpcs1'>Small Domestic Appliance</h6>
                    </div>
                    <h4 className='title'>
                        <span className="title-background">About company</span>
                    </h4>
                    <p className='text1s'>
                        <span>„With more than 10 years understanding the distribution and retail business, we are here to
                            cover the gaps of your local suppliers.”</span>
                        <p className='lastphrase'>There's nothing we cannot find a solution for.</p>
                    </p>
                </div>
                <div className='text2div'>
                    <p className='text2s'>TECH EDGE, a company founded in 2015 that specializes in distributing a wide range of IT equipment and solutions, including PCs, laptops, components, and software.
                    </p>
                    <p className='text2s'>
                        As an authorized reseller of HP and Lenovo, we pride ourselves on providing only the highest quality products. Our selection includes a variety of products to meet the needs of both commercial and consumer clients, including HP Commercial, HP Consumer Gaming, Lenovo Commercial and Consumer, and ASUS Consumer.
                    </p>
                    <p className='text2s'>We understand the importance of technology in today's business landscape. That's why we're committed to providing our customers with the latest and most advanced IT solutions to help them stay ahead of the competition. We work with our clients to understand their unique needs and provide customized solutions that meet their specific requirements.</p>
                    <p className='text2s'>
                        TECH EDGE is committed to providing an excellent customer experience and ensuring the fast and secure delivery of ordered products. The company focuses on maintaining a strong and trusted relationship with its customers, offering them a wide range of high-quality IT products and services.
                        Whether you're a small business owner or a large corporation, we're here to help you find the right IT solutions to meet your needs. We offer competitive pricing and reliable service to ensure that our customers always receive the best possible experience.
                        Thank you for considering TECH EDGE for your IT needs. We look forward to working with you to help you achieve your business objectives with the latest technology solutions.
                    </p>
                </div>
                <h4 className='title'>
                    <span className="title-background">Commercial team</span>
                </h4>
                <EmployeeCard />
            </div>

        </div>
    )
}
