import React from 'react';
import "./Brands.css";
import { MovingLogos } from './MovingLogos';
import {BrandDetails} from './BrandDetails';
import {MovingCategories} from './MovingCategories';
import somebackgrd from './somebackgrd.jpg';


export const Brands = () => {
  return (
    <div className='bigdiv'>
      <div className='movingBackground' style={{backgroundImage:`url(${somebackgrd})`, width:'100%',height:'100%'}}>
      <h4 >BRANDS & CATEGORIES</h4>
      <MovingLogos />
      <MovingCategories />
      </div>
      <p>Discover our portfolio</p>
      <BrandDetails style={{backgroundImage:`url(${somebackgrd})`, width:'100%',height:'100%'}}/>
    </div>

  );
};
