import React from 'react';
import HomeCarousel from './carousel/HomeCarousel';
import HomeText from './hometext/HomeText';
import "./Home.css";


export const Home =()=> {
    return(
        <div className='home' >
            <HomeCarousel className='homecarousel'/>
            <HomeText></HomeText> 
        </div>
    )
}
