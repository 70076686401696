import React from 'react';
import {empDetails} from './TeamDetails'
import './EmployeeCard.css';
export const EmployeeCard =()=> {
    return (
        <div className='page'>
          {empDetails.map((employee) => (
            <div key={employee.id} className='employee-card'>

              <div className='imgBackground'>
                <img className='empImg'src={employee.img} alt={employee.name} />
              </div>

              <div className="employee-details">
                <h3 className='empName' >{employee.name}</h3>
                <p  className='empJob' >{employee.job}</p>
                <p className='empEmail' >{employee.email}</p>
                <a className='empIn' href={employee.experience} >See my linkedin profile</a>
              </div>
              
            </div>
          ))}
        </div>
      );                                                                                                                   
}
