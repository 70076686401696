import BluetoothSpeakers from "./mediaCateg/BluetoothSpeakers.png"
import GamingConsolesAccessories from"./mediaCateg/GamingConsolesAccessories.png"
import Headphones from "./mediaCateg/Headphones.png"
import Laptops from "./mediaCateg/Laptops.png"
import Printers from "./mediaCateg/Printers.png"
import NotebookBags from "./mediaCateg/NotebookBags.png"
import Motherboards from "./mediaCateg/Motherboards.png"
import Monitors from "./mediaCateg/Monitors.png"
import MiceKeyboards from "./mediaCateg/MiceKeyboards.png"
import Projectors from "./mediaCateg/Projectors.png"
import WirelessRoutersRepeaters from "./mediaCateg/WirelessRoutersRepeaters.png"
import SmartPhones from "./mediaCateg/SmartPhones.png"
import TVStreamingDevices from "./mediaCateg/TVStreamingDevices.png"
import VideoCards from "./mediaCateg/VideoCards.png"
import ServersServerOptions from "./mediaCateg/ServersServerOptions.png"
import SDA from "./mediaCateg/SDA.png"
import TonerOfficeSupplies from "./mediaCateg/TonerOfficeSupplies.png"

export const categDetails=[
    {
    id:"1",
    name:"Bluetooth Speakers",
    img:BluetoothSpeakers,
    pictureSide:"left",
    },
    {
    id:"2",
    name:"Gaming Consoles & Accessories",
    img:GamingConsolesAccessories,
    pictureSide:"right"
    },
    {
    id:"3",
    name:"Headphones",
    img:Headphones,
    pictureSide:"left"
    },
    {
    id:"4",
    name:"Laptops",
    img:Laptops,
    pictureSide:"right"
    },
    {
    id:"5",
    name:"Printers",
    img:Printers,
    pictureSide:"left",
    },
    {
    id:"6",
    name:"Notebook Bags",
    img:NotebookBags,
    pictureSide:"right"
    },
    {
    id:"7",
    name:"Motherboards",
    img:Motherboards,
    pictureSide:"left"
    },
    {
    id:"8",
    name:"Monitors",
    img:Monitors,
    pictureSide:"right"
    },
    {
    id:"9",
    name:"Mice/Keyboards",
    img:MiceKeyboards,
    pictureSide:"left",
    },
    {
    id:"10",
    name:"Projectors",
    img:Projectors,
    pictureSide:"right"
    },
    {
    id:"11",
    name:"Wireless Routers & Repeaters",
    img:WirelessRoutersRepeaters,
    pictureSide:"left"
    },
    {
    id:"12",
    name:"Smart Phones",
    img:SmartPhones,
    pictureSide:"right"
    }, 
    {
    id:"13",
    name:"TV Streaming Devices",
    img:TVStreamingDevices,
    pictureSide:"right"
    },
    {
    id:"14",
    name:"Video Cards",
    img:VideoCards,
    pictureSide:"left"
    },
    {
    id:"15",
    name:"Toner Office Supplies",
    img:TonerOfficeSupplies,
    pictureSide:"right"
    },
    {
    id:"16",
    name:"Servers & Server Options",
    img:ServersServerOptions,
    pictureSide:"left"
    },
    {
    id:"17",
    name:"Smart Home Appliances",
    img:SDA,
    pictureSide:"right"
    }       
]