import React from "react";
import "./HomeCarousel.css";
import {images } from "./CarouselData";
import Carousel from "react-material-ui-carousel";
import { Paper } from "@mui/material";

const HomeCarousel = () => {
  // const [currImg, setCurrImg] = useState(0);

  return (
    <div className="home-carousel-container">
      <Carousel className="carousel">
        {
          images?.map((image) => {
            return (
              <Paper className="paper" key={image.id}  >
                    <div className="title-band">
                      <p className='card-title'>{image.title}</p>
                    </div>
                    <div className="carouselbackgroundimage" >
                      <img src={image.backgroundimg} alt="" height={'90%'} />
                    </div>

                    <div className="card-description">
                      <p>{image.subtitle}</p>
                    </div>
              </Paper>
            )

          })

        }
      </Carousel>
    </div>
  )
}

export default HomeCarousel;


