export const MenuItems=[
    {
        title:'Home',
        url:'/',
        cName:'nav-links'
    },
    {
        title:'AboutUS',
        url:'/aboutus',
        cName:'nav-links'
    },
    {
        title:'Brands',
        url:'/brands',
        cName:'nav-links'
    },
    {
        title:'Contact',
        url:'/contact',
        cName:'nav-links'
    }
]
