import React, {useRef,useState} from 'react';
import "./Contact.css";
import emailjs from '@emailjs/browser';
import styled from 'styled-components';
import ContactCardDetails from './ContactCardDetails';

export const Contact =()=> {
    const form = useRef();
    const [message, setMessage] = useState('');

    const sendEmail = (e) => {
      e.preventDefault();
      if (form.current.checkValidity()) {
      emailjs.sendForm(
        'service_amq574f', 
        'template_8qsocnj', 
        form.current, 
        'T7rnmcfIvRmHYl7aU')
        .then((result) => {
            console.log(result.text);
            setMessage('Message sent!')
        }, (error) => {
            console.log(error.text);
        });
      } else {
        setMessage('Please fill in all required fields.');
    }
    };

    return(
            <div className="contact">
                
                <StyledContactForm className='theform'>
                <form ref={form} onSubmit={sendEmail}>
                    <h4>Contact us:</h4>
                    <label>Company Name</label>
                    <input type="text" name="company_name" required />
                    <label>Name</label>
                    <input type="text" name="from_name" required />
                    <label>Email</label>
                    <input type="email" name="user_email" required />
                    <label>Message</label>
                    <textarea name="message" />
                    <input type="submit" value="Send" required />
                    <div className="message">{message}</div>
                </form>
                </StyledContactForm>
                <ContactCardDetails className='ccd'/>
                <iframe className='themap' title="Location Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2851.6948711094665!2d26.029160076240945!3d44.37785577107694!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40ae0173401f22e5%3A0x4896bb896fe765bd!2sStrada%20Spinului%2039%2C%20M%C4%83gurele%20077025!5e0!3m2!1sro!2sro!4v1698851579602!5m2!1sro!2sro"></iframe>
            </div>
    )
}

// Styles
const StyledContactForm = styled.div`
  form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    font-size: 16px;

    input {
      width: 100%;
      height: 35px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);

      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }

    textarea {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      max-height: 100px;
      min-height: 100px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);
      

      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }

    label {
      margin-top: 1rem;
    }

    input[type="submit"] {
      margin-top: 2rem;
      cursor: pointer;
      background: rgb(249, 105, 14);
      color: white;
      border: none;
    }
    .message {
      margin-top: 1rem;
      color: green;
    }
  }
`;






