import TransportBackground from "../media/transportbackground.gif"
import BrandBackground from "../media/brandbackground.gif"
import TeamBackground from "../media/teambackground.gif"
import msi from "../media/msi.png"

export const images=[
    {
    id:"1",
    cname:'trustteam',
    title:"RELIABLE TEAM",
    subtitle:"Our strength lies in our people – an experienced team that is grounded in trust, driven by openness, and fortified by a wealth of solid knowledge. We take pride in being your partners.",
    backgroundimg:TeamBackground,
    },
    {
    id:"2",
    cname:'fasttransport',
    title:"FAST TRANSPORT",
    subtitle:"We're dedicated to making sure your products reach their destination in the most efficient and cost-effective way possible. With strong collaborations across multiple transportation avenues, we are committed to providing fast and versatile transportation solutions to meet your needs.",
    backgroundimg:TransportBackground,
    },
    {
    id:"3",
    cname:'wantedbrands',
    title:"TOP BRANDS",
    subtitle:"Our partnerships are built on a foundation of innovation ensuring that you have access to the latest technology and solutions for your every need. We're proud to collaborate with some of the industry's most renowned brands. ",
    backgroundimg:BrandBackground
    },
    {
    id:"4",
    cname:'msi',
    title:"OFFICIAL DISTRIBUTOR",
    subtitle:"Starting from 2023, we became an official MSI distributor. Contact us for more information!",
    backgroundimg:msi
    }
]