import './App.css';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Navbar from './layout/Navbar';
import {BrowserRouter as Router, Routes,Route} from 'react-router-dom';
import {Home} from './pages/home/Home';
import {Aboutus} from './pages/aboutus/Aboutus';
import {Brands} from './pages/brands/Brands';
import {Contact} from './pages/contact/Contact';
import { Footer } from './layout/Footer';


function App() {
  return (

    <div className="App">
      <Router>
      <Navbar/>
      <Routes>
        <Route exact path='/' element={<Home/>}></Route>
        <Route exact path='/aboutus' element={<Aboutus/>}></Route>
        <Route exact path='/brands' element={<Brands/>}></Route>
        <Route exact path='/contact' element={<Contact/>}></Route>
      </Routes>
      </Router>
      <Footer className='footer'/>
    </div>
  );
}

export default App;
