import React, { useState } from 'react';
import { categDetails } from './CategDetails';
import './BrandDetails.css';
// import secbackgrd from './secbackgrd.tif';
import CategList from './CategList';

export const BrandDetails = () => {
  const useToggle = (initialState = {}) => {
    const [states, setStates] = useState(initialState);

    const toggle = (key, index) => {
      setStates((prevStates) => ({
        ...prevStates,
        [key]: prevStates[key].map((state, i) => (i === index ? !state : state)),
      }));
    };
    return [states, toggle];
  };

  const categListInstance = new CategList();
  const data = categListInstance.getData();
  const [toggleStates, setToggleState] = useToggle({
    firstdivlist: Array(4).fill(false),
    middledivlist: Array(3).fill(false),
    lastdivlist: Array(4).fill(false),
  });

  const divData = [
    { key: 'firstdivlist', data: data.slice(0, 4) },
    { key: 'middledivlist', data: data.slice(4, 7) },
    { key: 'lastdivlist', data: data.slice(7, 11) },
  ];

  return (
    // <div className='brandDetailsCard' style={{ backgroundImage: `url(${secbackgrd})`, width: '100%', height: '100%' }}>
    <div className='brandDetailsCard'>
      {divData.map(({ key, data: dataList }, divIndex) => (
        <div key={key} className='cardDetails'>
          {dataList.map((item, index) => (
            <div key={index}>
              <div className='brandButton' onClick={() => setToggleState(key, index)}>
                {item.title}
              </div>
              {toggleStates[key][index] && (
                <div className='content'>
                  <div className='elements'>
                    {item.categories.map((sub, subIndex) => (
                      <div key={subIndex} className='categDetails'>
                        {categDetails?.map((det) =>
                          sub.categ === det.name ? <img key={det.name} src={det.img} alt={det.name} /> : null
                        )}
                        <p>{sub.categ}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
