import React, {Component} from 'react';
import{MenuItems} from "./MenuItems";
import './Navbar.css'
import LogoPicture from './LogoPicture';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTimes } from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';

class Navbar extends Component{
    state={ clicked: false}
handleClick=() => {
    this.setState({clicked:!this.state.clicked})
}

    render(){
        return(
            <header className='NavbarItems'>  
                <div className="navbar-logo" style={{backgroundImage:`url(${LogoPicture.tel})`}} ></div>
                <div className='techedge' >TECH EDGE
                <div className="cc">computers & components</div>
                </div>

                <div className='menu-icon' onClick={this.handleClick}>
                    <FontAwesomeIcon icon={this.state.clicked ? faTimes:faBars} />
                </div>

                <ul className={this.state.clicked ? 'nav-menu active':'nav-menu'}>
                    {MenuItems.map((item, index) => {
                        return(
                            <li key={index}>
                                <a className={item.cName} href={item.url}>
                                    {item.title}
                                </a>
                            </li>
                        )
                    })}
                    
                </ul>
            </header>
        )
    }
}
export default Navbar;


