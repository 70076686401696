import React from "react";
import './Footer.css'
import { SocialIcon } from 'react-social-icons'


export const Footer = () => {
  return (
    <div className="footer">
      {/* <div className="sb_footer-links-div"> */}
        <h4>Coming soon on</h4>
        <div className="socialmedia">
          <SocialIcon network="facebook"></SocialIcon>
          <SocialIcon network="linkedin"></SocialIcon>
        </div>
      {/* </div> */}
      <hr></hr>
        <div className="sb_footer-copyright">
          <p>
            @{new Date().getFullYear()} Tech Edge. All rights reserved.
          </p>
        </div>
    </div>
  );
}
