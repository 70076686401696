import React, { Fragment } from 'react';
export default class ContactCardDetails extends React.Component{
    constructor(){
        super();
        this.state = {
            data: [
                {
                    title:'COMPANY INFORMATION',
                    details:[
                        {info:'Company name: TECH EDGE S.R.L.'},
                        {info:'V.A.T. :  RO 34202136'},
                        {info:'Registration NO: J23 / 5442 / 2020'}
                    ]
                },
                {
                    title:'CONTACT',
                    details:[
                        {info:'E-mail: office@e-sales.tech'},
                        {info:'Phone: (+40) 743918273'},
                        {info:'Address: 39 Spinului Street, Magurele, Romania'},
                        {info:'Zip code: 077025'}
                    ]
                },
                {
                    title:'TIME SCHEDULE',
                    details:[
                        {info:'Monday to Friday: 09:00 AM - 05:00 PM'},
                        {info:'Saturday: close'},
                        {info:'Sunday: close'}
                    ]
                }
            ]
        }
    }

    splitTextByColon = (text) => {
        return text.split(':');
      };

      splitTextAndRemoveFirst = (text) => {
        const poz=text.indexOf(':');
        if (poz !== -1) {
        const resultArray = text.substring(poz + 1);
        return resultArray;
    } else {
        return text;
      }
      };  

    render(){
        return(
            <div className='cardshape'>
                {
                    this.state.data.map((item)=>
                        <div className='carddetails'>
                            <h4>{item.title}</h4>
                            {item.details.map((sub)=>
                            <Fragment>
                            <p style={{color:'#003a70'}}><b>{(sub.info).split(':')[0]}: </b> </p>
                            <p ><span style={{lineHeight:'1.1'}}>{this.splitTextAndRemoveFirst(sub.info)}</span></p>
                            </Fragment>
                                )
                            }
                        </div>
                    )
                }
            </div>
        )
    }
}
