import React from 'react';
import './HomeText.css';
import { details } from "./TextDetails";

const HomeText = () => {
    
    return (
        <div className="cardsDiv" >
            {
                details?.map((detail, index) => {
                    return (
                        <div className="thecard">
                            <div className='valuetitle'>{detail.title}</div>
                            <img src={detail.img} alt="" />
                            <div className='cardText'> 
                                <p>{detail.description}</p>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default HomeText;
