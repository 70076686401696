import React from 'react';
import './MovingCategories.css';
import { categDetails } from './CategDetails'

export const MovingCategories = () => {
  return (
    <div className="moving-categ-container">
      {
        categDetails?.map((detail, index) => {
          return (
            <div className="moving-categ">
              <img src={detail.img} alt=""/>
            </div>
          )
        })
      } 
    </div>
  );
};

